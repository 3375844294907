export function init(deviationFormEl) {
	const formEl = deviationFormEl.querySelector('form');
	const submitEl = document.getElementById('deviation-form-submit');
	const loadingEl = document.querySelector('.deviation-form-loading');

	if (formEl) {
		formEl.addEventListener('submit', () => {
			submitEl.disabled = true;
			submitEl.classList.add('hidden');
			loadingEl.classList.remove('hidden');
		});
	}
}
